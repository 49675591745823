import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createTourMilestonePopup } from '../actions/popup';

import { makeCancelable, oauth } from '../utils';

class TourIndex extends Component{
  constructor(props) {
    super(props);

    this.state = {
      feedbacks: props.feedbacks,
      tour_started: props.tour_started
    };
  }

  UNSAFE_componentWillReceiveProps(np) {
    this.setState({ feedbacks: np.feedbacks, tour_started: np.tour_started });
  }

  componentDidMount() {
    if(this.state.tour_started == 0) {
      this.props.onCreateTourMilestonePopup('welcome');
    }
  }

  render() {
    const { onCreateTourMilestonePopup } = this.props;
    const { feedbacks, tour_started } = this.state;
    const float_left = {
      float: 'left'
    };
    const opacity_low = {
      opacity: 0.5
    };
    const left_opacity_low = {
      opacity: 0.5,
      float: 'left'
    };
    return(
      <div className="main-content tour-color-red tour">
        <div className="medium-12 columns" style={{paddingLeft: 0}}>
          <div className="tour-block medium-3 column"
            onClick={e => {
              e.preventDefault();
              onCreateTourMilestonePopup('create-client');
	    }}>
            <img src={feedbacks['create-client'] ? "../images/create-done.png" : "../images/create-active.png"} className="tour-status" />
            <img src="../images/client.jpg" />
            <span className="index-text">Create your first client</span>
          </div>
          <div className="tour-block medium-3 column" style={!feedbacks['create-client'] && !feedbacks['create-project'] ? opacity_low : null}
            onClick={e => {
              e.preventDefault();
              if (feedbacks['create-client']) {
                onCreateTourMilestonePopup('create-project');
              }
	    }}>
            <img src={feedbacks['create-project'] ? "../images/create-done.png" : !feedbacks['create-client'] && !feedbacks['create-project'] ? "../images/create-inactive.png" : "../images/create-active.png"} className="tour-status" />
            <img src="../images/project.jpg" />
            <span className={!feedbacks['create-client'] && !feedbacks['create-project'] ? 'index-text-inactive' : 'index-text'}>Create your first project</span>
          </div>
          <div className="tour-block medium-3 column" style={!feedbacks['create-project'] && !feedbacks['create-presentation'] ? opacity_low : null}
            onClick={e => {
              e.preventDefault();
              if (feedbacks['create-project']) {
                onCreateTourMilestonePopup('create-presentation');
	      }
	    }}>
            <img src={feedbacks['create-presentation'] ? "../images/create-done.png" : !feedbacks['create-project'] && !feedbacks['create-presentation'] ? "../images/create-inactive.png" : "../images/create-active.png"} className="tour-status" />
            <img src="../images/presentation.jpg" />
            <span className={!feedbacks['create-project'] && !feedbacks['create-presentation'] ? 'index-text-inactive' : 'index-text'}>Create your first presentation</span>
          </div>
          <div className="tour-block medium-3 column" style={!feedbacks['create-presentation'] && !feedbacks['create-sales-order'] ? opacity_low : null}
            onClick={e => {
              e.preventDefault();
              if (feedbacks['create-presentation']) {
                onCreateTourMilestonePopup('create-sales-order');
	      }
	    }}>
            <img src={feedbacks['create-sales-order'] ? "../images/create-done.png" : !feedbacks['create-presentation'] && !feedbacks['create-sales-order'] ? "../images/create-inactive.png" : "../images/create-active.png"} className="tour-status" />
            <img src="../images/sales-order.jpg" />
            <span className={!feedbacks['create-presentation'] && !feedbacks['create-sales-order'] ? 'index-text-inactive' : 'index-text'}>Create your first sales order</span>
          </div>
          <div className="tour-block medium-3 column" style={!feedbacks['create-sales-order'] && !feedbacks['create-purchase-order'] ? left_opacity_low : float_left}
            onClick={e => {
              e.preventDefault();
              if (feedbacks['create-sales-order']) {
                onCreateTourMilestonePopup('create-purchase-order');
	      }
	    }}>
            <img src={feedbacks['create-purchase-order'] ? "../images/create-done.png" : !feedbacks['create-sales-order'] && !feedbacks['create-purchase-order'] ? "../images/create-inactive.png" : "../images/create-active.png"} className="tour-status" />
            <img src="../images/purchase-order.jpg" />
            <span className={!feedbacks['create-sales-order'] && !feedbacks['create-purchase-order'] ? 'index-text-inactive' : 'index-text'}>Create your first purchase order</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let feedbacks = Object.values(state.feedbacks).map(f => ({key: f.tour_step_key, value: f.feedback})).reduce((o, k) => { o[k.key] = k.value; return o; }, {});
  let tour_started = state.identity.tour_started;

  return {
    feedbacks,
    tour_started
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCreateTourMilestonePopup: (milestone) => {
      dispatch(createTourMilestonePopup(milestone));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TourIndex);
