import React, { Component } from 'react';
import { connect } from 'react-redux';

import { reducers } from '../store/configureTourStore';
import withReducers from '../store/withReducers';
import Header from '../containers/Header';
import MainSection from './MainSection';
import TourTitle from '../components/TourTitle';
import TourIndex from '../components/TourIndex';

class TourApp extends Component {

  render() {
    const { popups, trial_end_date } = this.props;

    return (
      <div>
        <Header trial_end_date={trial_end_date}>
          <TourTitle />
        </Header>
        <MainSection popups={popups}>
          <TourIndex />
        </MainSection>
        {popups.length ? <div className="reveal-overlay" tabIndex="-1" aria-hidden="true" style={{display: 'block', overflow: 'hidden'}}></div> : undefined}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    popups: state.display.popups,
    trial_end_date: state.identity.trial_end_date ? state.identity.trial_end_date : null
  };
};

export default withReducers(connect(mapStateToProps)(TourApp), reducers);
