import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createInviteColleaguesPopup } from '../actions/popup';

class TourTitle extends Component {
  constructor(props) {
      super(props);
  }

  render() {
    const { user_first_name, trial_end_date, onCreateInviteColleaguesPopup } = this.props;
    const marginTop50 = {
        marginTop: '50px'
    };

    return (
      <div className="tour-color-red">
        <div className="large-12 columns">
          <div className="row no-maxWidth">
            <h3>Hey {user_first_name},</h3>
            <div className="row medium-12" style={{display: 'inline-block'}}>
              <h4 style={{display: 'inline-block'}}>Welcome to commonsku!&nbsp;&nbsp;</h4>
              <button className="button tour-title-btn tour-invite-colleagues" onClick={onCreateInviteColleaguesPopup}>+ Invite Colleagues</button>
              <a href="https://commonsku.freshdesk.com/support/home" target="_blank" className="button tour-title-btn tour-support">Support</a>
              <p className="tour-trial-end-date">Trial end date: {trial_end_date}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user_first_name: state.identity.user_first_name,
    trial_end_date: state.identity.trial_end_date
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCreateInviteColleaguesPopup: () => {
      dispatch(createInviteColleaguesPopup());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TourTitle);
